import { graphql } from "gatsby"
import React from "react"
import CareerBanner from "../components/CareerBanner/CareerBanner"
import SEO from "../components/seo"
import Layout from "../components/layout"
import LandingContent from "../components/LandingContent/LandingContent"
import ImageContentComponent from "../components/ImageContentComponent/ImageContentComponent"
import CareerOpportunities from "../components/CareerOpportunities/CareerOpportunities"
import JobOppenings from "../components/JobOppenings/JobOppenings"
import CareerSlider from "../components/CareerSlider/CareerSlider"
import useDeviceMedia from "../hooks/useDeviceMedia"

const CareerLanding = ({ data }) => {
  const pageData = data?.strapiPage
  const careerList=data?.allStrapiCareer?.edges?.length>0?data?.allStrapiCareer?.edges:""
  const {isMobile}=useDeviceMedia()

  let popUpData=""

  if(pageData?.add_page_modules?.length>0){
     const results=pageData?.add_page_modules?.filter(item=>item.__typename==="STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT")
     if(results?.length>0){
      popUpData=results[0]
     }
  }

  return (
    <Layout popUpData={popUpData} popularSearch={pageData?.select_popular_search?.title}>
      
      <CareerBanner pageData={pageData} />

      {pageData.add_page_modules?.map((item, i) => {
        return (
          <div>
            {item?.__typename === "STRAPI__COMPONENT_COMPONENTS_TITLE_DESC" && (
              <LandingContent data={item} />
            )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT" && (
              <ImageContentComponent
                data={item}
                pageData={pageData}
                career={true}
              />
            )}

            {item?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_VALUATION_IMAGE" && isMobile&& item.count==="1"&&(
              <CareerSlider data={item} pageData={pageData} />
            )}

            {item?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_VALUATION_IMAGE" && !isMobile&& (
              <CareerSlider data={item} pageData={pageData} count={item.count}/>
            )}
            


            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_PRIMARY_MODULE" &&
              item.title !== "Current Vacancies" && (
                <CareerOpportunities data={item} pageData={pageData} />
              )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_PRIMARY_MODULE" &&
              item.title === "Current Vacancies" && (
                <JobOppenings data={item} pageData={pageData} careerList={careerList} />
              )}
          </div>
        )
      })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage

  let myimgtransforms = ""
  if (PageData?.imagetransforms?.banner_section_banner_image_Transforms) {
    myimgtransforms = JSON.parse(
      PageData?.imagetransforms?.banner_section_banner_image_Transforms
    )
  }
  let pageImg = ""
  if (Object.keys(myimgtransforms)?.length > 0) {
    let pageImg_filt = []
    for (const myimgtransform in myimgtransforms) {
      if (typeof myimgtransforms[myimgtransform]["webp"] !== "undefined") {
        pageImg_filt = Object.values(myimgtransforms[myimgtransform]["webp"])
        break
      }
    }
    if (pageImg_filt.length > 0) pageImg = pageImg_filt.length>1?pageImg_filt[1]:PageData?.banner?.image?.url;
  }
  const imageUrl=pageImg

  
  // const imageUrl=PageData?.banner?.image?.url;

  return (
      <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} 
      imageUrl={imageUrl}
      />
  )
}

export default CareerLanding

export const query = graphql`
  query ($page_id: String) {
    allStrapiCareer(filter: {publish: {eq: true}}) {
      edges {
        node {
          location
          title
          strapi_id
          slug
          image {
            url
          }
          imagetransforms {
            image_Transforms
          }
        }
      }
    }

    strapiPage(id: { eq: $page_id }) {
      ...PageFragment

      add_page_modules {
        ... on STRAPI__COMPONENT_COMPONENTS_TITLE_DESC {
          __typename
          id
          description {
            data {
              description
            }
          }
          title
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_VALUATION_IMAGE {
          id
          __typename
          count
          add_image {
            images {
              url
              alternativeText
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_PRIMARY_MODULE {
          __typename
          id
          title
          content {
            data {
              content
            }
          }
          items {
            title
            sub_title
            video_url
            image {
              url
              alternativeText
            }
            cta{
              link{
                slug
              }
              custom_link
              link_type
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT {
          __typename
          id
          left_right_block {
            image {
              url
              alternativeText
            }
            cta_label
            menu{
              slug
            }
            custom_link
            title
            description {
              data {
                description
              }
            }
            video_url
            layout
          }
        }
      }
    }
  }
`
